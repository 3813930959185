import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { ChevronRight } from 'lucide-react';
import { useClient } from '../ClientContext';

const styles = {
  container: {
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  adminPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
  },
  textSection: {
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  chevronIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    py: '12px',
    ml: '10px',
    '& .MuiPaper-root': {
      boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, 0.05)',
    },
  },
  menuItem: (client, selectedClient) => ({
    mx: '8px',
    fontWeight: client === selectedClient || client === 'All Dashboards' ? 600 : 'normal',
    fontSize: client === 'All Dashboards' ? '14px' : '16px',
    backgroundColor: client === selectedClient ? '#F3EAFF' : 'none',
    '&:hover': {
      backgroundColor: '#F3EAFF',
      borderRadius: '8px',
    },
  }),
  buttonDashboard: {
    backgroundColor: '#772EDD',
    color: '#FFFFFF',
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '16px',
    '&:hover': {
      backgroundColor: '#5C21BF',
    },
  },
  buttonStaging: {
    color: '#772EDD',
    textTransform: 'none',
    fontWeight: 'bold',
    pt: '8px',
  },
};

const CLIENT_CONFIG = {
  Tidal: {
    prodUrl: process.env.REACT_APP_DSP_DASH_URL,
    stagingUrl: process.env.REACT_APP_DSP_DASH_URL_STG,
  },
  SoundExchange: {
    prodUrl: process.env.REACT_APP_SX_DASH_URL,
    stagingUrl: process.env.REACT_APP_SX_DASH_URL_STG,
  },
  iHeart: {
    prodUrl: process.env.REACT_APP_IHEART_DASH_URL,
    stagingUrl: process.env.REACT_APP_IHEART_DASH_URL_STG,
  },
};

const ClientSelection = ({ clients, defaultClient }) => {
  const { selectedClient, setSelectedClient } = useClient();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (defaultClient) {
      setSelectedClient(defaultClient);
      // eslint-disable-next-line
    } else if (defaultClient == null && selectedClient == 'All Dashboards') {
      setSelectedClient('Tidal');
    }
    // eslint-disable-next-line
  }, [defaultClient, setSelectedClient]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (client) => {
    setAnchorEl(null);
    if (client) {
      setSelectedClient(client);
    }
  };

  const getUrls = () => {
    const defaultUrls = { prodUrl: '#', stagingUrl: '#' };
    return CLIENT_CONFIG[selectedClient] || defaultUrls;
  };

  const { prodUrl, stagingUrl } = getUrls();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.adminPanel} onClick={handleMenuClick}>
        <Box sx={styles.textSection}>
          <Typography variant="body2" color="textSecondary">
            Viewing Admin Panel for
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {selectedClient}
          </Typography>
        </Box>
        <Box sx={styles.chevronIcon}>
          <IconButton size="small">
            <ChevronRight size={16} />
          </IconButton>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sx={styles.menu}
        onClose={() => handleMenuClose(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {clients.map((client) => (
          <MenuItem key={client} onClick={() => handleMenuClose(client)} sx={styles.menuItem(client, selectedClient)}>
            {client}
          </MenuItem>
        ))}
      </Menu>
      <Divider sx={{ width: '100%' }} />
      <Box sx={{ padding: '16px' }}>
        <Button variant="contained" fullWidth sx={styles.buttonDashboard} href={prodUrl}>
          Go to Dashboard
        </Button>
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Button variant="text" fullWidth sx={styles.buttonStaging} href={stagingUrl}>
        Go to Staging
      </Button>
    </Box>
  );
};

export default ClientSelection;
