import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../components/Layout';
import { User } from 'lucide-react';
import UserManagementSummary from '../components/UserManagementSummary';
import UserManagementTable from '../components/UserManagementTable';
import { useClient } from '../ClientContext';

const styles = {
  container: { padding: '48px' },
  header: { display: 'flex', alignItems: 'center', gap: '8px', mb: '8px' },
  subHeader: { mb: '32px' },
  title: { fontSize: '24px', fontWeight: 700 },
};

const UserManagementPage = ({ user, accessToken }) => {
  const { selectedClient } = useClient();
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const sxUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-sx`;
  const tidalUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-tidal`;
  const adminUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-admin`;
  const iheartUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-iheart`;

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const fetchData = async (endpoint, setDataCallback) => {
      try {
        const response = await fetch(endpoint, {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch data');

        const data = await response.json();
        setDataCallback(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', err);
        setError('Error fetching data.');
      }
    };

    let userEndpoint;
    switch (selectedClient) {
      case 'SoundExchange':
        userEndpoint = sxUserEndpoint;
        break;
      case 'Tidal':
        userEndpoint = tidalUserEndpoint;
        break;
      case 'iHeart':
        userEndpoint = iheartUserEndpoint;
        break;
      default:
        userEndpoint = tidalUserEndpoint;
    }

    fetchData(userEndpoint, (data) => {
      switch (selectedClient) {
        case 'SoundExchange':
          setUsers(data.sxUsers || []);
          break;
        case 'Tidal':
          setUsers(data.tidalUsers || []);
          break;
        case 'iHeart':
          setUsers(data.iheartUsers || []);
          break;
        default:
          setUsers(data.tidalUsers || []);
      }
    });
    fetchData(adminUserEndpoint, (data) => setAdmins(data.adminUsers || []));
  }, [selectedClient, sxUserEndpoint, tidalUserEndpoint, iheartUserEndpoint, adminUserEndpoint, accessToken]);

  const countUsers = (users, admins) => {
    let externalUsers = 0;
    let beatdappUsers = 0;

    users.forEach((user) => {
      if (user.email.endsWith('@beatdapp.com')) {
        beatdappUsers++;
      } else {
        externalUsers++;
      }
    });

    return {
      totalUsers: [...users, ...admins].length,
      externalUsers,
      beatdappUsers,
      totalAdmins: admins.length,
    };
  };

  const userStats = countUsers(users, admins);

  if (error) return <div>{error}</div>;

  return (
    <Layout profilePic={user.picture}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <User size={24} />
          <Typography sx={styles.title}>User Management</Typography>
        </Box>
        <Box sx={styles.subHeader}>
          <Typography variant="body2" gutterBottom>
            Manage users and their permissions for the selected dashboard
          </Typography>
        </Box>
        <UserManagementSummary
          totalUsers={userStats.totalUsers}
          externalUsers={userStats.externalUsers}
          beatdappUsers={userStats.beatdappUsers}
          totalAdmins={userStats.totalAdmins}
        />
        <UserManagementTable accessToken={accessToken} />
      </Box>
    </Layout>
  );
};

export default UserManagementPage;
